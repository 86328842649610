import React, { useState, useEffect } from "react";
import 'jquery-validation';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import { MultiSelect } from 'primereact/multiselect';

const Role = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    // const [employee_name, setEmployeeName] = useState('');
    // const [project_name, setProjectName] = useState('');
    const [profit_center, setProfitCenter] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);

    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchProjectAndUsers = async () => {
            try {
                const [projectResponse, userResponse, departmentResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/Project/get_all`),
                    axios.post(`${API_BASE_URL}/Users/get_users_list`),
                    axios.get(`${API_BASE_URL}/Department/get_all`),
                ]);
    
                // Extracting data based on the API response structure
                const projectsData = projectResponse.data?.data || [];
                const usersData = userResponse.data?.results || [];
                const departmentsData = departmentResponse.data?.data || [];  // Adjusted for department structure
    
                setProjects(projectsData);
                setUsers(usersData);
                setPerPage(10);
                // Map project options
                const projectOptions = projectsData.map(prj => ({
                    name: prj.project_name,
                    value: prj.mst_projects_id,
                }));
                setProjectOptions(projectOptions);
    
                // Map employee options
                const employeesOptions = usersData.map(emp => ({
                    name: emp.employee_name,
                    value: emp.mst_users_id,
                }));
                setEmployeeOptions(employeesOptions);
    
                // Map department options
                const departmentOptions = departmentsData.map(dep => ({
                    name: dep.department_name,
                    value: dep.mst_departments_id,
                }));
                setDepartmentOptions(departmentOptions);
    
                console.log("Department IDs:", departmentsData.map(dep => dep.mst_departments_id));
            } catch (error) {
                console.error('Error fetching projects or users:', error);
                toast.error('Error fetching projects or users');
            }
        };
        fetchProjectAndUsers();
    }, [API_BASE_URL]);

    const handleProjectChange = (e) => {
        //alert(e.value);
        setSelectedProjects(e.value);
        console.log('Selected Projects:', e.value);
    };
    const handleEmployeeChange = (e) => {
        setSelectedEmployees(e.value);
        console.log('Selected Employee:', e.value);
    };
    const handleDepartmentChange = (e) => {
        setSelectedDepartments(e.value);
        console.log('Selected Department:', e.value);
    };

    const searchTable = async(page, perPage)=>{
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('employee_id', document.getElementsByClassName("employee_id")[0]?.value || '');
            formData.append('project_id', document.getElementsByClassName("project_id")[0]?.value || '');
            formData.append('profit_center', document.getElementsByClassName("profit_center")[0]?.value || '');
            formData.append('department_id', document.getElementsByClassName("department_id")[0]?.value || '');
            formData.append('from_date', document.getElementsByClassName("from_date")[0]?.value || '');
            formData.append('to_date', document.getElementsByClassName("to_date")[0]?.value || '');

            const response = await axios.post(
                `${API_BASE_URL}/Timesheet/get_all_timesheet_log_search`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: { page, per_page: perPage }
                }
                
            );
            setData(response.data.results);
            setTotalRows(response.data.total);
            setLoading(false);
          
        } catch (error) {
            console.error('Error exporting filtered data:', error);
            toast.error('Failed to Search data');
        }
    }
    const fetchData = async (page, perPage) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("mst_users_id", localStorage.getItem('mst_users_id'));

            const response = await axios.post(
                `${API_BASE_URL}/Timesheet/get_all_timesheet_log`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: { page, per_page: perPage }
                }
            );

            setData(response.data.results);
            //console.log("response "+response.data.results)
            setTotalRows(response.data.total);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage, perPage);
    }, [currentPage, perPage]);

    const handlePageChange = page => {
        setCurrentPage(page);
    };
    const getTimesheetStatusByIdExcel = (id) => {
       
        if (id === 0 || id === "0") {
            return 'Draft';
        }
        if (id === 1 || id === "1") {
            return 'Submitted';
        }
        if (id === 2 || id === "2") {
            return "Approved";
        }
        
        // Default if no match
        return <span className="text-danger">Unknown</span>;
    };
    const exportToExcel = () => {
        const exportData = data.map(item => ({
            'Project Name': getProjectNameById(item.mst_projects_id),
            'Profit Center': getPCByProjectId(item.mst_projects_id),
            'Employee': getUserNameById(item.user_id),
            'Department': item.department_name,
            'Company Code': item.company_code,
            'Status': getTimesheetStatusByIdExcel(item.status),
            'Year': item.year,
            'Month': new Date(item.date).getMonth() + 1,
            'Week No': item.week_no,
            'Date': item.date,
            'Hrs': item.hrs
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Timesheet Data");
        XLSX.writeFile(workbook, "timesheet_data.xlsx");
    };
  // const exportFilteredToExcel = async () => {
    //     try {
    //         const formData = new FormData();
    //         formData.append('employee_id', document.getElementsByClassName("employee_id")[0]?.value || '');
    //         formData.append('project_id', document.getElementsByClassName("project_id")[0]?.value || '');
    //         formData.append('profit_center', document.getElementsByClassName("profit_center")[0]?.value || '');
    //         formData.append('from_date', document.getElementsByClassName("from_date")[0]?.value || '');
    //         formData.append('to_date', document.getElementsByClassName("to_date")[0]?.value || '');
    
    //         const response = await axios.post(
    //             `${API_BASE_URL}/Usersdatafetch/get_timesheet_log_data`,
    //             formData,
    //             {
    //                 headers: { 'Content-Type': 'multipart/form-data' },
    //             }
    //         );
    
    //         // Display the success message
    //         //const responseData = await response.message;
    //         alert(response.data.message);  // This will show "Data fetched and email sent successf"
    //         console.log(response.data.message); // Log the response for debugging
    
    //     } catch (error) {
    //         console.error('Error exporting filtered data:', error);
    //         toast.error('Failed to export filtered data');
    //     }
    // };
    
    const getProjectNameById = id => projects.find(pro => pro.mst_projects_id === id)?.project_name || <span className="text-danger">-</span>;

    const getPCByProjectId = id => projects.find(pro => pro.mst_projects_id === id)?.profit_center || <span className="text-danger">-</span>;

    const getUserNameById = id => users.find(use => use.mst_users_id === id)?.employee_name || <span className="text-danger">-</span>;

    const getTimesheetStatusById = (id) => {
       
        if (id === 0 || id === "0") {
            return <span className="badge bg-primary">Draft</span>;
        }
        if (id === 1 || id === "1") {
            return <span className="badge bg-warning">Submitted</span>;
        }
        if (id === 2 || id === "2") {
            return <span className="badge bg-success">Approved</span>;
        }
        
        // Default if no match
        return <span className="text-danger">Unknown</span>;
    };

    const columns = [
        { 
            name: 'Project Name', 
            selector: row => getProjectNameById(row.mst_projects_id), 
            sortable: false, 
            style: { width: '200px' } // Set column width here
        },
        { 
            name: 'Profit Center', 
            selector: row => getPCByProjectId(row.mst_projects_id), 
            sortable: false, 
            style: { width: '150px' } // Set column width here
        },
        { 
            name: 'Employee', 
            selector: row => getUserNameById(row.user_id), 
            sortable: false, 
            style: { width: '200px' } // Set column width here
        },
        {
            name: 'Department',
            selector: row => row.department_name,
            sortable: false,
            style: { width: '200px' } // Set column width here
        },
        { 
            name: 'Year', 
            selector: row => row.year, 
            sortable: false, 
            style: { width: '60px' } // Set column width here
        },
        { 
            name: 'Month', 
            selector: row => new Date(row.date).getMonth() + 1, 
            sortable: true, 
            style: { width: '60px' } // Set column width here
        },
        { 
            name: 'Week No.', 
            selector: row => row.week_no, 
            sortable: false, 
            style: { width: '60px' } // Set column width here
        },
        {
            name: 'Date',
            selector: row => {
                const [year, month, day] = row.date.split('-');
                return `${day}-${month}-${year}`;
            },
            sortable: true,
            style: { width: '150px' } // Set column width here
        },
        { 
            name: 'Hrs', 
            selector: row => row.hrs, 
            sortable: false, 
            style: { width: '80px' } // Set column width here
        },
        {
            name: 'Status',
            cell: row => getTimesheetStatusById(row.status), 
            sortable: false,
            style: { width: '120px' } // Set column width here
        },
        { 
            name: 'Updated By', 
            selector: row => row.updated_by, 
            sortable: false, 
            style: { width: '150px' } // Set column width here
        },
        { 
            name: 'Created On', 
            selector: row => row.created_on, 
            sortable: false, 
            style: { minWidth: '150px', maxWidth: '150px' } // Set fixed width
        }
    ];
   
  
    
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Log Report</h4>
                            </div>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="form-label">Project Name</label>
                                           
                                            <MultiSelect 
                                                value={selectedProjects}
                                                onChange={handleProjectChange}
                                                options={projectOptions} 
                                                optionLabel="name" 
                                                filter 
                                                placeholder="Select Project" 
                                                maxSelectedLabels={3} 
                                                className="w-full md:w-30rem " 
                                            />
                                             <input type="hidden" className="project_id" value={selectedProjects}/>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">Profit Center</label>
                                            <input
                                                type="text"
                                                placeholder="Search Profit Center"
                                                value={profit_center}
                                                onChange={e => setProfitCenter(e.target.value)}
                                                className="form-control profit_center"
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">Employee</label>
                                          
                                            <MultiSelect 
                                                value={selectedEmployees}
                                                onChange={handleEmployeeChange}
                                                options={employeeOptions} 
                                                optionLabel="name" 
                                                filter 
                                                placeholder="Select Employee" 
                                                maxSelectedLabels={3} 
                                                className="w-full md:w-30rem" 
                                            />
                                             <input type="hidden" className="employee_id" value={selectedEmployees}/>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">Department</label>                                          
                                            <MultiSelect 
                                                value={selectedDepartments}
                                                onChange={handleDepartmentChange}
                                                options={departmentOptions} 
                                                optionLabel="name" 
                                                filter 
                                                placeholder="Select Department" 
                                                maxSelectedLabels={3} 
                                                className="w-full md:w-30rem" 
                                            />
                                             <input type="hidden" className="department_id" value={selectedDepartments}/>
                                        </div>

                                        <div className="col-md-2">
                                            <label className="form-label">From Date</label>
                                            <DatePicker
                                                selected={fromDate}
                                                onChange={date => setFromDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                isClearable
                                                className="form-control from_date"
                                                placeholderText="Select From Date "
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">To Date</label>
                                            <DatePicker
                                                selected={toDate}
                                                onChange={date => setToDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                isClearable
                                                className="form-control to_date"
                                                placeholderText="Select To Date "
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6 text-left">
                                        <button className="btn btn-primary" onClick={exportToExcel}>Export All</button>
                                        </div>
                                        <div className="col-md-6 text-right">
                                        <button className="btn btn-success" onClick={searchTable}>Search</button>
                                        </div>
                                       

                                       

                                        {/* <button className="btn btn-primary" onClick={exportFilteredToExcel}>Export Filtered Data</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
    columns={columns}
    data={data}
    progressPending={loading}
    pagination={true}
    paginationPerPage={perPage}
    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
    paginationTotalRows={totalRows}
    onChangePage={handlePageChange}
    customStyles={{
        headRow: {
            style: {
                backgroundColor: '#0f6839',
                color: 'white',
                fontWeight: 'bold'
            },
        },
        headCells: {
            style: {
                color: 'white',
                fontWeight: 'bold',
                whiteSpace: 'nowrap' // Ensures headers stay in one line
            }
        },
        pagination: {
            style: {
                padding: '10px'
            }
        }
    }}
/>
                </div>
            </div>
        </div>
    );
};

export default Role;
