import './App.css';
import { Routes, Route } from 'react-router-dom';

// Pages
import Login from './Login';
import Dashboard from './pages/Dashboard';
import Department from './pages/Department';
import Role from './pages/Role';
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';

import Users from './pages/Users';
import NotFound from './pages/NotFound';
import Project from './pages/Project';

// Reports
import TimesheetReport from './Report/TimesheetReport';
import LogReport from './Report/LogReport';
import UserLogReport from './Report/UserLogReport';
import EmailSetting from './Report/EmailSetting';

// Components
// import Header from './components/Header';
// import Sidebar from './components/Sidebar';
import ProtectedRoute from './ProtectedRoute';
import MainLayout from './layouts/MainLayout'; // New layout for reducing repetition

function App() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Login />} />

      {/* Protected Routes */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <MainLayout>
              <Dashboard />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-project"
        element={
          <ProtectedRoute>
            <MainLayout>
              <Project />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-department"
        element={
          <ProtectedRoute>
            <MainLayout>
              <Department />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-role"
        element={
          <ProtectedRoute>
            <MainLayout>
              <Role />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-user" exact
        element={
          <ProtectedRoute>
            <MainLayout>
              <AddUser />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
         path="/edit-user"
        element={
          <ProtectedRoute>
            <MainLayout>
              <EditUser />
            </MainLayout>
          </ProtectedRoute>
        }
      />

  {/* <Route
          path="/edit-user/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditUser />
              </MainLayout>
            </ProtectedRoute>
          }
        /> */}
   
      
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <MainLayout>
              <Users />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/email-setting"
        element={
          <ProtectedRoute>
            <MainLayout>
              <EmailSetting />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/timesheet-report"
        element={
          <ProtectedRoute>
            <MainLayout>
              <TimesheetReport />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/log-report"
        element={
          <ProtectedRoute>
            <MainLayout>
              <LogReport />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-log-report"
        element={
          <ProtectedRoute>
            <MainLayout>
              <UserLogReport />
            </MainLayout>
          </ProtectedRoute>
        }
      />

      {/* Catch All Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
