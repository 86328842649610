import { useNavigate } from 'react-router-dom';
import React from 'react';

const Header = () =>{
   const navigate = useNavigate();
   const handleLogout = () => {
       localStorage.clear(); // Clear local storage
       navigate('/'); // Redirect to the homepage
   };
   
   const full_name = localStorage.getItem("full_name");
   const role_name = localStorage.getItem("role_name");


   return(
      <header id="page-topbar">
            <div className="layout-width">
               <div className="navbar-header">
                  <div className="d-flex">
                    {/* //  {/* <!-- LOGO --> */}
                     <div className="navbar-brand-box horizontal-logo">
                        <a href="index.html" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="17" />
                        </span>
                        </a>
                        <a href="index.html" className="logo logo-light">
                        <span className="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="17" />
                        </span>
                        </a>
                     </div>
                     {/* <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger material-shadow-none" id="topnav-hamburger-icon">
                        <span className="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                        </span>
                     </button> */}
                    {/* //  {/* <!-- App Search--> */}
                    
                  </div>
                  <div className="d-flex align-items-center">
                     
                     {/* <div className="ms-1 header-item d-none d-sm-flex">
                        <button type="button" className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle" data-toggle="fullscreen">
                        <i className='bx bx-fullscreen fs-22'></i>
                        </button>
                     </div>
                     <div className="ms-1 header-item d-none d-sm-flex">
                        <button type="button" className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle light-dark-mode">
                        <i className='bx bx-moon fs-22'></i>
                        </button>
                     </div> */}
                    
                     <div className="dropdown ms-sm-3 header-item topbar-user">
                        <button type="button" className="btn material-shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="d-flex align-items-center">
                           <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar" />
                           <span className="text-start ms-xl-2">
                              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{full_name}</span>
                              <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{role_name}</span>
                           </span>
                        </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                           {/* <!-- item--> */}
                           <h6 className="dropdown-header">Welcome {full_name}!</h6>
                         {/* <a className="dropdown-item" href=""><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Profile</span></a>
                           <div className="dropdown-divider"></div>
                           <a className="dropdown-item" href=""><i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Settings</span></a>*/}
                           <a className="dropdown-item" onClick={handleLogout} href=""><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
      </header>         
    )
}

export default Header;