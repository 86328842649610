import {Link} from 'react-router-dom'
const Sidebar = ()=>{
    return(
        <div className="app-menu navbar-menu" style={{background:"rgb(255 255 255)",borderRight:"#f7f8fc",color:"#000"}}>
            {/* <!-- LOGO --> */}
            <div className="navbar-brand-box">
            {/* <!-- Dark Logo--> */}
            <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                    <img src="assets/images/renew-logo.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                    <img src="assets/images/renew-logo.png" alt="" height="27"/>
                </span>
            </Link>
            {/* <!-- Light Logo--> */}
            <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                    <img src="assets/images/renew-logo.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                    <img src="assets/images/renew-logo.png" alt="" height="27" />
            </span>
            </Link>
            <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                <i className="ri-record-circle-line"></i>
            </button>
            </div>
            <div className="dropdown sidebar-user m-1 rounded">
            <button type="button" className="btn material-shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="d-flex align-items-center gap-2">
            <img className="rounded header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar" />
            <span className="text-start">
            <span className="d-block fw-medium sidebar-user-name-text">Anna Adame</span>
            <span className="d-block fs-14 sidebar-user-name-sub-text"><i className="ri ri-circle-fill fs-10 text-success align-baseline"></i> <span className="align-middle">Online</span></span>
            </span>
            </span>
            </button>
            <div className="dropdown-menu dropdown-menu-end">
                {/* <!-- item--> */}
                <h6 className="dropdown-header">Welcome Anna!</h6>
                <Link className="dropdown-item" to="/pages-profile.html"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Profile</span></Link>
                <Link className="dropdown-item" to="/apps-chat.html"><i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Messages</span></Link>
                <Link className="dropdown-item" to="/apps-tasks-kanban.html"><i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Taskboard</span></Link>
                <Link className="dropdown-item" to="/pages-faqs.html"><i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Help</span></Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/pages-profile.html"><i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Balance : <b>$5971.67</b></span></Link>
                <Link className="dropdown-item" to="/pages-profile-settings.html"><span className="badge bg-success-subtle text-success mt-1 float-end">New</span><i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Settings</span></Link>
                <Link className="dropdown-item" to="/auth-lockscreen-basic.html"><i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></Link>
                <Link className="dropdown-item" to="/auth-logout-basic.html"><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span></Link>
            </div>
            </div>
            <div id="scrollbar">
                <div className="container-fluid">
                    <div id="two-column-menu">
                    </div>
                    {localStorage.getItem("mst_users_id") === "1" && (
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="menu-title"><span data-key="t-menu">Menu</span></li>
                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="/dashboard" aria-expanded="false">
                                    <i className="ri-dashboard-2-line"></i> <span data-key="t-dashboards">Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="/#sidebarDashboards" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                                    <i className=" ri-list-check-2"></i> <span data-key="t-dashboards">Masters</span>
                                </Link>
                                <div className="collapse menu-dropdown" id="sidebarDashboards">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <Link to="/add-project" className="nav-link" data-key="t-analytics"> Projects </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/add-department" className="nav-link" data-key="t-analytics"> Departments </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/add-role" className="nav-link" data-key="t-crm"> Roles </Link>
                                        </li>                               
                                    </ul>
                                </div>
                            </li>   
                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="/#sidebarUsers" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                                    <i className=" ri-team-line"></i> <span data-key="t-dashboards">Users</span>
                                </Link>
                                <div className="collapse menu-dropdown" id="sidebarUsers">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <Link to="/users" className="nav-link" data-key="t-crm"> Add /  List Users </Link>
                                        </li>                               
                                    </ul>
                                </div>
                            </li>  

                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="/#sidebarEmail" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                                    <i className="ri-file-line"></i> <span data-key="t-dashboards">Email Report</span>
                                </Link>
                                <div className="collapse menu-dropdown" id="sidebarEmail">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <Link to="/email-setting" className="nav-link" data-key="t-analytics"> Email Report Settings </Link>
                                        </li>                                                                   
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="/#sidebarReports" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                                    <i className="ri-file-line"></i> <span data-key="t-dashboards">Reports</span>
                                </Link>
                                <div className="collapse menu-dropdown" id="sidebarReports">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <Link to="/timesheet-report" className="nav-link" data-key="t-analytics"> Timesheet Report </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/log-report" className="nav-link" data-key="t-analytics">Timesheet Log Report </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/user-log-report" className="nav-link" data-key="t-analytics">User Log Report </Link>
                                        </li>                                                                   
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    )}

                </div>
                {/* <!-- Sidebar --> */}
            </div>
            <div className="sidebar-background"></div>
        </div>
        
    )
}

export default Sidebar