import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate


const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();    
        try {
            const formData = new FormData();
            formData.append("email", email);  
            formData.append("password", password); 
            
            const API_BASE_URL  = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.post(`${API_BASE_URL}/validate_login`, formData,{
                headers: {
                    "Content-Type": "multipart/form-data", 
                  }
            });   


            
            if(response.data.success == 1){
                const { mst_users_id, full_name,role_name, email: userEmail,token } = response.data.data[0];
                localStorage.setItem("mst_users_id", mst_users_id);

                localStorage.setItem("full_name", full_name);
                localStorage.setItem("email", userEmail);
                localStorage.setItem("token", token);
                localStorage.setItem("role_name", role_name);
                navigate("/dashboard"); 
            }else{
                setIsVisible(true);
            }          
        } catch (error) {
            setIsVisible(true);
        }
    };

    return (
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay"></div>
            {/* <!-- auth-page content --> */}
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card overflow-hidden card-bg-fill galaxy-border-none">
                                <div className="row justify-content-center g-0">
                                    <div className="col-lg-6">
                                        <div className="p-lg-5 p-4 auth-one-bg h-100">
                                            <div className="bg-overlay" style={{background:"#fff"}}></div>
                                            <div className="position-relative h-100 d-flex flex-column">
                                                <div className="mb-4">
                                                    <a href="" className="d-block">
                                                        <img src="assets/images/renew-logo.png" alt="" height="18" />
                                                    </a>
                                                </div>
                                                <div className="mt-auto">
                                                    <div className="mb-3">
                                                        <i className="ri-double-quotes-l display-4 text-success"></i>
                                                    </div>

                                                    <div id="qoutescarouselIndicators" className="carousel slide" data-bs-ride="carousel">
                                                        <div className="carousel-indicators">
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                        </div>
                                                        <div className="carousel-inner text-center text-white-50 pb-5">
                                                            <div className="carousel-item active">
                                                                <p className="fs-15 fst-italic" style={{color:"#000"}}>" Track Time, Boost Productivity "</p>
                                                            </div>
                                                            <div className="carousel-item">
                                                                <p className="fs-15 fst-italic" style={{color:"#000"}}>" Master Your Time, Elevate Your Work."</p>
                                                            </div>
                                                            <div className="carousel-item">
                                                                <p className="fs-15 fst-italic" style={{color:"#000"}}>" Smart Time Tracking for Smarter Workflows! "</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end carousel --> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end col --> */}

                                    <div className="col-lg-6">
                                        <div className="p-lg-5 p-4">
                                            <div>
                                                <h5 className="text-primary">Welcome Back !</h5>
                                                <p className="text-muted">Sign in to continue to Timesheet Manager.</p>
                                            </div>

                                            <div className="mt-4">
                                                <form onSubmit={handleSubmit}>

                                                    <div className="mb-3">
                                                        <label htmlFor="username" className="form-label">Email</label>
                                                        <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} id="username" placeholder="Enter email" />
                                                    </div>

                                                    <div className="mb-3">
                                                        {/* <div className="float-end">
                                                            <a href="auth-pass-reset-cover.html" className="text-muted">Forgot password?</a>
                                                        </div> */}
                                                        <label className="form-label" htmlFor="password-input">Password</label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control pe-5 password-input" placeholder="Enter password" id="password-input" />
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                        <label className="form-check-label" htmlFor="auth-remember-check">Remember me</label>
                                                    </div> */}

                                                    <div className="mt-4">
                                                        <button className="btn btn-success w-100" type="submit">Sign In</button>
                                                    </div>  
                                                    <div className="alert alert-danger mt-3 border-0 mb-xl-0 material-shadow" style={{ display: isVisible ? "block" : "none" }} role="alert">
                                                        <strong> Error! </strong> Invalid email or password!
                                                    </div>                                                  
                                                </form>
                                            </div>                                           
                                        </div>
                                    </div>
                                    
                                    {/* <!-- end col --> */}
                                </div>
                                {/* <!-- end row --> */}
                            </div>
                            {/* <!-- end card --> */}
                        </div>
                        {/* <!-- end col --> */}

                    </div>
                    {/* <!-- end row --> */}
                </div>
                {/* <!-- end container --> */}
            </div>
            {/* <!-- end auth page content --> */}

            {/* <!-- footer --> */}
            <footer className="footer galaxy-border-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <p className="mb-0">&copy;
                                    <script>document.write(new Date().getFullYear())</script> Pro-TEAM Solutions Pvt. Ltd.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- end Footer --> */}
        </div>
        
    );
}

export default Login;